const cartActionTypes = {
    TOGGLE_CART_DROPDOWN: "TOGGLE_CART_DROPDOWN",
    ADD_ITEM_TO_CART: "ADD_ITEM_TO_CART",
    REMOVE_ITEM_FROM_CART: "REMOVE_ITEM_FROM_CART",
    CLEAR_ITEM: "CLEAR_ITEM",
    CLEAR_CART: "CLEAR_CART",
    UPDATE_FIRESTORE_CART_ITEMS: "UPDATE_FIRESTORE_CART_ITEMS",
    GET_CART_ITEMS_FROM_FIRESTORE: "GET_CART_ITEMS_FROM_FIRESTORE"
}

export default cartActionTypes